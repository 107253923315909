.login {
  background: url("../pages/img/wizard/background.jpg") no-repeat center bottom fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;

  .content {
    border: 1px solid #d35a2a;
    border-top: 24px solid #d35a2a;
    box-shadow: 0 1px 3px rgba(0, 0, 0, .1), 0 1px 2px rgba(0, 0, 0, .18);
    margin-bottom: 50px;
    padding-left: 35px;
    padding-right: 35px;
    padding-top: 10px;
    width: 316px;

    .login-form {
      position: relative;

      h1 {
        color: #d35a2a;
        font-family: Montserrat;
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 5px;
        text-transform: uppercase;
      }

      h2 {
        color: #23282c;
        font-family: Montserrat;
        font-size: 20px;
        font-weight: bold;
        margin-top: 5px;
        text-transform: uppercase;
      }

      .form-group {
        .input-icon {
          border-left: 1px #e3e3e4 !important;

          i {
            &.user, &.password {
              display: inline-block;
              line-height: 14px;
              margin-top: 17.5px;
              -webkit-font-smoothing: antialiased;

              &:after {
                content: '';
                display: block;
                height: 16px;
              }
            }

            &.user:after {
              background: url("../pages/img/login/icons/user.png") no-repeat 0px 0px;
            }

            &.password:after {
              background: url("../pages/img/login/icons/password.png") no-repeat 0px 0px;
            }
          }
        }

        input {
          background-color: #e3e3e4;
          border: 1px #e3e3e4 !important;
          color: #23282c;
          font-family: Montserrat;
          height: 51px;

          &::placeholder {
            font-weight: lighter;
            font-size: 12px;
            text-transform: uppercase;
          }
        }
      }

      .rememberme {
        font-family: Montserrat;
        font-size: 12px;
        //font-weight: lighter;

        span {
          border-color: #d35b29;
          border-radius: 17px;
          height: 15px;
          left: 3px;
          top: 1px;
          width: 15px;

          &:after {
            background-color: #d35b29;
            border: 1px solid white;
            border-radius: inherit;
            height: 100%;
            left: 0;
            transform: none;
            top: 0;
            width: 100%;
          }
        }
      }
    }

    .form-actions {
      border-bottom: none;
      padding-top: 30px;

      .btn.accent {
        &.btn-outline {
          background: 0 0;
          border-color: #d35b29;
          color: #d35b29;
          font-family: Montserrat;
          font-size: 18px;
          font-weight: bold;
          height: 51px;
          margin-bottom: 5px;
          text-transform: uppercase;
          width: 100%;

          &.active,
          &:active,
          &:active:focus,
          &:active:hover,
          &:focus,
          &:hover {
            background-color: #d35b29;
            border-color: #d35b29;
            color: #FFF;
          }
        }
      }

      a.reset-password {
        font-family: Montserrat;
        font-size: 12px;
      }
    }

    //.forgot-password {
    //  margin-top: 5px;
    //
    //  p {
    //    font-family: Montserrat;
    //    font-size: 12px;
    //    //font-weight: lighter;
    //
    //    a.btn.reset-password {
    //      &.btn-outline {
    //        background: 0 0;
    //        border-color: #3b3d3f;
    //        color: #3b3d3f;
    //        font-family: Montserrat;
    //        font-size: 18px;
    //        font-weight: bold;
    //        height: 51px;
    //        text-transform: uppercase;
    //        width: 100%;
    //        line-height: 34px;
    //
    //        &.active,
    //        &:active,
    //        &:active:focus,
    //        &:active:hover,
    //        &:focus,
    //        &:hover {
    //          background-color: #3b3d3f;
    //          border-color: #3b3d3f;
    //          color: #FFF;
    //        }
    //      }
    //    }
    //  }
    //}

    .create-account {
      margin-top: 5px;

      p {
        font-family: Montserrat;
        font-size: 12px;
        //font-weight: lighter;

        a.btn.register {
          &.btn-outline {
            background: 0 0;
            border-color: #3b3d3f;
            color: #3b3d3f;
            font-family: Montserrat;
            font-size: 18px;
            font-weight: bold;
            height: 51px;
            text-transform: uppercase;
            width: 100%;
            line-height: 34px;

            &.active,
            &:active,
            &:active:focus,
            &:active:hover,
            &:focus,
            &:hover {
              background-color: #3b3d3f;
              border-color: #3b3d3f;
              color: #FFF;
            }
          }
        }
      }
    }
  }
}